import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";
import AssetRates from "../assets/AssetRates";
import Loading from "../Loading";
import {
  fetchTransaction,
  sendTransaction,
  clearTransaction,
  validatePromoCode,
  setError
} from "../../actions";
import { formatMoney, formatCrypto } from "../../utilities/Format";
import { addressRegExes, amountRegExes } from "../../utilities/RegEx";
import AssetRatesNew from "../assets/AssetRatesNew";

const paymentMethods = {
  bank: "bank",
  // epay: 'epay',
  easypay: "easypay",
  // trastra: 'trastra',
  usdc: 'usdc',
  phyre: 'phyre'
};

const paymentMethodsFees = {
  bank: 0,
  // epay: 0,
  easypay: 0,
  // trastra: 0,
  phyre: 0,
  usdc: 0
};

const paymentMethodsIcons = {
  bank: "/img/bank.png",
  // epay: '/img/epay.png',
  easypay: "/img/easy-pay.png",
  // trastra: "/img/trastra.png",
  phyre: "/img/phyre.png",
  usdc: "/img/assets/usdc.svg",
};

const receivingMethods = {
  account: "account",
  address: "address",
  binance: "binance",
};

class AccountBuyFormNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rateSub: 0.0,
      amount: 0.0,
      price: 0.0,
      fees: 0.0,
      total: 0.0,
      rate: 0.0,
      paymentMethod: "bank",
      receiver: "",
      receivingMethod: "account",
      formValid: false,
      formErrors: {
        amount: "",
        price: "",
        paymentMethod: "",
        receiver: "",
        receivingMethod: "",
        promoCode: ""
      },
      amountValid: false,
      priceValid: false,
      paymentMethodValid: true,
      receiverValid: true,
      receivingMethodValid: true,
      isLoading: false,
      promoCode: "",
      promoCodeUse: false
    };

    this.onCalculatorChange = this.onCalculatorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCalculatorChange(amount, price, field, rate) {
    this.setState({ amount, price, rate }, () => {
      return this.validateField(
        field,
        field === "amount" ? amount : price
      ).then(resp => {
        return this.validateField(
          field === "amount" ? "price" : "amount",
          field === "amount" ? price : amount
        ).then(resp => {
          this.recalculateTotal();
        });
      });
    });
  }

  onPaymentMethodChange(event) {
    let name = event.target.name;
    let paymentMethod = event.target.value;
    this.setState({ paymentMethod }, () => {
      this.validateField(name, paymentMethod).then(() => {
        this.validateField("price", this.state.price);
        this.recalculateTotal();
      });
    });
    this.callParentAction(paymentMethod);
  }

  onReceivingMethodChange(event) {
    let name = "receivingMethod";
    let value = event.target.value;
    if (value === "account") {
      this.setState(
        {
          receiver: this.props.account.identificator,
          receivingMethod: value
        },
        () => {
          this.validateField(name, value);
        }
      );
    } else if (value === "binance") {
      this.setState(
        {
          receiver: '',
          receivingMethod: value,
          formValid: false
        },
        () => {
          this.validateField(name, value);
        }
      );
    } else {
      this.setState(
        {
          receiver: this.props.user[
            this.props.account.title.toLowerCase() + "_address"
          ],
          receivingMethod: value,
          formValid: false
        },
        () => {
          this.validateField(name, value);
        }
      );
    }
  }

  onReceiverChange(event) {
    let name = "receiver";
    let value = event.target.value;
    this.setState({ receiver: value }, () => {
      this.validateField(name, value);
    });
  }

  onPromoCodeChange(event) {
    let value = event.target.value;
    this.setState({ rateSub: 0.0, promoCode: value, formErrors: { ...this.state.formErrors, promoCode: "" } });
  }

  onPromoCodeSubmit() {
    this.setState({ rateSub: 0.0, formErrors: { ...this.state.formErrors, promoCode: "" } });
    if (this.state.promoCode != '') {
      this.props.validatePromoCode({ promoCode: this.state.promoCode }).then(resp => {
        if (resp.success) {
          this.setState({ rateSub: parseFloat(this.props.config.cashback_sub), promoCodeUse: true }, () => {
            this.recalculateTotal();
          });
        } else {
          this.setState({ formErrors: { ...this.state.formErrors, promoCode: "promoCodeIsInvalid" } });
        }
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.formValid) {
      this.setState({ isLoading: true }, () => {
        this.props.sendTransaction({
          ...this.state,
          account: this.props.account,
          type: "BUY",
          currency: this.props.currency
        }).then(resp => {
          this.setState({ isLoading: false });
        });
      });
    }
  }

  recalculateTotal() {
    let total = 0.0;
    let price = parseFloat(this.state.price);
    let fees = this.getPaymentMethodFees();

    total = price + fees;
    this.setState({
      fees,
      total
    });
  }

  getPrice(type, amount) {
    let price = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate;
      tempRate =
        parseFloat(aRate.rate / this.props.currency.rate) +
        (parseFloat(aRate.rate / this.props.currency.rate) *
          (this.props.config.buy_percent - this.state.rateSub)) /
        100;
      if (aRate.type == type) { //  && (type != "BGN" && type != "EUR" && type != "USDC" && type != "RON")
        price = parseFloat(amount * tempRate).toFixed(2);
      } else if (aRate.type == this.props.account.type) {
        price = parseFloat(amount * tempRate).toFixed(this.props.account.decimals);
      }
    });
    return price;
  }

  getPaymentMethodFees() {
    if (this.state.paymentMethod == "easypay" || this.state.paymentMethod == "bank") {
      let price = parseFloat(this.state.price);
      let fees = price * (1.9 / 100); // Bank tax, original 1.9% represented as 0.019, now is 0.9
      return fees;
    } else {
      return paymentMethodsFees[this.state.paymentMethod];
    }
  }

  callParentAction(value) {
    if (this.props.action) {
      this.props.action(value);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ paymentMethod: props.payment }, () => {
      this.validateField("paymentMethod", props.payment);
    });
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  validateField(fieldName, value) {
    return new Promise(resolve => {
      let formErrors = this.state.formErrors;
      let amountValid = this.state.amountValid;
      let priceValid = this.state.priceValid;
      let paymentMethodValid = this.state.paymentMethodValid;
      let receiverValid = this.state.receiverValid;
      let receivingMethodValid = this.state.receivingMethodValid;

      switch (fieldName) {
        case "amount":
          amountValid =
            parseFloat(value).toPrecision(9) != "0.00000000" &&
            value.match(amountRegExes.crypto);
          formErrors.amount = amountValid ? "" : "IsInvalid";
          if (parseFloat(value) < parseFloat(this.props.config['buy_min_' + this.props.account.type])) {
            formErrors.amount = "IsBelowMin";
          }
          amountValid = !formErrors.amount.length;
          break;
        case "price":
          formErrors.price =
            value && value.match(amountRegExes.fiat) ? "" : "IsInvalid";
          if (
            value * 1 < this.getPrice(this.props.account.type, this.props.config['buy_min_' + this.props.account.type])
          ) {
            formErrors.price = 'IsBelowMin';
          } else if (
            value * 1 > this.props.config.buy_max ||
            (this.state.paymentMethod === "easypay" && value * 1 > 4900)
          ) {
            formErrors.price = "IsAboveMax";
          }
          priceValid = !formErrors.price.length;
          break;
        case "paymentMethod":
          paymentMethodValid = paymentMethods[value].length > -1;
          formErrors.paymentMethod = paymentMethodValid ? "" : "IsInvalid";
          break;
        case "receiver":
          if (this.state.receivingMethod == 'address') {
            receiverValid = value.match(addressRegExes[this.props.account.type]);
          } else if (value.trim() != '' || this.state.receivingMethod == 'account') {
            receiverValid = true;
          } else if (value.trim() == '') {
            receiverValid = false;
          }
          formErrors.receiver = receiverValid ? "" : "IsInvalid";
          break;
        case "receivingMethod":
          receivingMethodValid = receivingMethods[value].length > -1;
          formErrors.receivingMethod = receivingMethodValid ? "" : "IsInvalid";
          if (receivingMethods[value] != 'account') {
            receiverValid = false;
          } else {
            receiverValid = true;
          }
          break;
        default:
          break;
      }

      return this.setState(
        {
          formErrors,
          amountValid,
          priceValid,
          paymentMethodValid,
          receiverValid,
          receivingMethodValid,
          formValid:
            amountValid &&
            priceValid &&
            paymentMethodValid &&
            receiverValid &&
            receivingMethodValid
        },
        () => {
          return resolve(true);
        }
      );
    });
  }

  componentDidMount() {
    if (this.props.currency.type == 'USDC') {
      const paymentMethod = "usdc";
      this.setState({ paymentMethod });
      this.callParentAction(paymentMethod);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.type != this.props.currency.type) {
      if ((this.state.paymentMethod == "easypay" || this.state.paymentMethod == "phyre") && this.props.currency.type != 'USDC') {
        const paymentMethod = "bank";
        this.setState({ paymentMethod }, () => {
          this.validateField("paymentMethod", paymentMethod).then(() => {
            this.recalculateTotal();
          });
        });
        this.callParentAction(paymentMethod);
      }
      if (this.props.currency.type == 'USDC') {
        const paymentMethod = "usdc";
        this.setState({ paymentMethod }, () => {
          this.validateField("paymentMethod", paymentMethod).then(() => {
            this.recalculateTotal();
          });
        });
        this.callParentAction(paymentMethod);
      }
    }
  }

  render() { 
    if (this.props.auth.authenticated && this.props.user.totp == 'none' && this.props.auth.totpRequired) {
      this.props.setError('2FA Authentication Required');
      return (
        <Redirect to="/settings" />
      );
    }

    if (this.state.isLoading) {
      return <Loading />;
    } else if (!_.isEmpty(this.props.transaction)) {
      if (this.state.paymentMethod == "bank") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="buying" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.buy" /> {this.props.account.title}!
                  </h5>
                  <div className="payment-text">
                    <p>
                      <Translate id="toBuy2_1" />{" "}
                      <strong>{this.props.account.type}</strong>',{" "}
                      <Translate id="toBuy2_2" />{" "}
                    </p>
                    <p><Translate id="bankIban" />:</p>
                    {(this.props.activeLanguage.code != 'bg') ? (
                      <div>
                        <p><strong><Translate id="bankForName" />: BoneX Ltd</strong></p>
                        <p><strong>IBAN: BG32 ESPY 4004 0052 9457 57</strong></p>
                      </div>
                    ) : (
                      <div>
                        <p><strong><Translate id="bankForName" />: БОНЕККС</strong></p>
                        <p><strong>IBAN: BG32 ESPY 4004 0052 9457 57</strong></p>
                      </div>
                    )}
                    <p>
                      <strong>
                        <Translate id="sum" />:{" "}
                        {this.props.transaction.total}{" "}
                        <Translate id={JSON.parse(this.props.transaction.data).currency.symbol} />{" "}
                      </strong>{" "}
                    </p>
                  </div>
                  <p><Translate id="afterReceiving" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}
                  </span>
                  {this.state.receivingMethod === "binance" ? (
                    <p>
                      Binance:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "address" ? (
                    <p>
                      <Translate id="toTheWallet" /> {this.props.account.title} wallet <Translate id="address" />:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "account" ? (
                    <p> <Translate id="toYourBalance" /> {this.props.account.title} <Translate id="balance" /> </p>
                  ) : ""}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.paymentMethod == "epay") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="buying" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.buy" /> {this.props.account.title}!
                  </h5>
                  <p><Translate id="afterReceiving" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  {this.state.receivingMethod === "binance" ? (
                    <p>
                      Binance:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "address" ? (
                    <p>
                      <Translate id="toTheWallet" /> {this.props.account.title} wallet <Translate id="address" />:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "account" ? (
                    <p> <Translate id="toYourBalance" /> {this.props.account.title} <Translate id="balance" /> </p>
                  ) : ""}
                </div>
                <form action="https://www.epay.bg/" method="post">
                  <input type="hidden" name="PAGE" value="paylogin" />
                  <input
                    type="hidden"
                    name="ENCODED"
                    value={this.props.transaction.encoded}
                  />
                  <input
                    type="hidden"
                    name="CHECKSUM"
                    value={this.props.transaction.checksum}
                  />
                  <input
                    type="hidden"
                    name="URL_OK"
                    value={`https://api.bonex.org/accounts/transactions/update/${this.props.transaction.id}/success`}
                  />
                  <input
                    type="hidden"
                    name="URL_CANCEL"
                    value={`https://api.bonex.org/accounts/transactions/update/${this.props.transaction.id}/fail`}
                  />
                  <button
                    type="submit"
                    className="btn btn-action btn-rounded crypto-button crypto-sell"
                  >
                    <Translate id="to" /> ePay
                  </button>
                  <br />
                </form>
              </div>
            </div>
          </div>
        );
      } else if (this.state.paymentMethod == "easypay") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="buying" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.buy" /> {this.props.account.title}!
                  </h5>
                  <p>
                    <Translate id="pleaseTransfer" />{" "}
                    <strong>
                      {this.props.transaction.total}{" "}
                      <Translate id={JSON.parse(this.props.transaction.data).currency.symbol} />{" "}
                    </strong>{" "}
                    <Translate id="atOffice" /><strong>Easypay</strong> <Translate id="withCode" />
                  </p>
                  <span>{this.props.transaction.idn}</span>
                  <p><Translate id="afterReceiving" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  {this.state.receivingMethod === "binance" ? (
                    <p>
                      Binance:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "address" ? (
                    <p>
                      <Translate id="toTheWallet" /> {this.props.account.title} wallet <Translate id="address" />:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "account" ? (
                    <p> <Translate id="toYourBalance" /> {this.props.account.title} <Translate id="balance" /> </p>
                  ) : ""}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.paymentMethod == "phyre") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="buying" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.buy" /> {this.props.account.title}!
                  </h5>
                  <p><Translate id="payByOpening" /> <a href={this.props.transaction.phyre_link} target="_blank"><Translate id="link" /> </a></p>
                  <p><Translate id="orThrough" />:</p>
                  <p><img src={this.props.transaction.phyre_qr} /></p>
                  <p><Translate id="afterReceiving" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  {this.state.receivingMethod === "binance" ? (
                    <p>
                      Binance:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "address" ? (
                    <p>
                      <Translate id="toTheWallet" /> {this.props.account.title} wallet <Translate id="address" />:<br />
                      <span>
                        {JSON.parse(this.props.transaction.data).receiver}
                      </span>
                    </p>
                  ) : ""}
                  {this.state.receivingMethod === "account" ? (
                    <p> <Translate id="toYourBalance" /> {this.props.account.title} <Translate id="balance" /> </p>
                  ) : ""}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.paymentMethod == "usdc") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="buying" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.buy" /> {this.props.account.title}!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else if (!_.isEmpty(this.props.account)) {
      return (
        <div className="account-action-form-wrapper">
          <div className="col-md-12">
            <form onSubmit={this.onSubmit} className="account-action-form">
              <div style={{ textAlign: "center" }}>
                <AssetRatesNew
                  account={this.props.account}
                  action={this.onCalculatorChange}
                  diff={this.props.config.buy_percent - this.state.rateSub}
                  sign="+"
                  minAmount={parseFloat(this.props.config['buy_min_' + this.props.account.type])}
                  minTotal={this.getPrice(this.props.account.type, this.props.config['buy_min_' + this.props.account.type])}
                  maxTotal={
                    this.state.paymentMethod === "easypay"
                      ? 4900
                      : this.props.config.buy_max
                  }
                  rates={this.props.rates}
                  currency={this.props.currency}
                />
                <div
                  className={`col-md-12 form-group ${!this.state.paymentMethodValid ? "has-error" : "has-success"
                    }`}
                >
                  <div
                    className="form-info-box sm-margin payments-wrapper-title"
                    style={{
                      width: "82%",
                      margin: "auto",
                      textAlign: "left",
                    }}
                  >
                    <strong>
                      <Translate id="selectPaymentOption" />
                    </strong>
                  </div>
                  <div className="payments-wrapper-new">
                    {_.map(paymentMethods, (title, method) => {
                      if (
                        (method == "easypay" && this.props.currency.type == "EUR") ||
                        (method == "easypay" && (this.props.activeLanguage.code == 'ro' || this.props.currency.type === "RON")) ||
                        (method == "phyre" && this.props.currency.type == "EUR") ||
                        (method == "phyre" && this.props.currency.type == "RON") ||
                        (method == "usdc" && this.props.account.type == "USDC") ||
                        (this.props.currency.type == "USDC" && this.props.account.type != "USDC" && method != "usdc")
                      ) {
                        return;
                      } else {
                        return (
                          <label className="custom-check-element new-custom-check-payment-element" key={method}>
                            <input
                              type="radio"
                              onChange={this.onPaymentMethodChange.bind(this)}
                              value={method}
                              checked={this.state.paymentMethod === method}
                              name="paymentMethod"
                            />
                            <i></i>
                            <img src={paymentMethodsIcons[method]} />
                            <Translate id={`payments.${title}`} />
                          </label>
                        );
                      }
                    })}
                  </div>
                  {this.state.formErrors.paymentMethod != "" ? (
                    <div
                      className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                    >
                      <span>
                        <Translate id="errors.paymentIsInvalid" />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`col-md-12 form-group ${!this.state.receivingMethodValid ? "has-error" : "has-success"
                    }`}
                >
                  <div
                    className="form-info-box sm-margin payments-wrapper-title"
                    style={{
                      width: "82%",
                      margin: "auto",
                      textAlign: "left",
                    }}
                  >
                    <strong>
                      <Translate id="fillDetailsReceipt" />
                    </strong>
                  </div>
                  <div className="payments-wrapper-new">
                    {_.map(receivingMethods, (title, method) => {
                      // Only show "account" if paymentMethod is "usdc"
                       if (method !== "account") {
                         return null;
                       }

                      return (
                        <label
                          className="custom-check-element new-custom-check-payment-element"
                          style={{ width: "32%" }}
                          key={method}
                        >
                          <input
                            name="receivingMethod"
                            type="radio"
                            onChange={this.onReceivingMethodChange.bind(this)}
                            value={method}
                            checked={this.state.receivingMethod === method}
                          />
                          <i></i>
                          <Translate id={`receivers.${title}`} />
                        </label>
                      );
                    })}
                  </div>
                  {this.state.formErrors.receivingMethod != "" ? (
                    <div
                      className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                    >
                      <span>
                        <Translate id="errors.receivingMethodIsInvalid" />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.receivingMethod === "address" ? (
                  <div
                    className={`col-md-12 form-group ${!this.state.receiverValid ? "has-error" : "has-success"
                      }`}
                  >
                    <div className="custom-style-select no-border label-animated has-content payment-field-wrapper">
                      <div>
                        <div className="form-info-box sm-margin payments-wrapper-title">
                          {/* <strong>
                          <Translate id="receiving" /> <Translate id="in" />{" "}
                          Wallet <Translate id="address" />
                        </strong> */}
                          {(['USDC'].indexOf(this.props.account.type) != -1) ? (
                            <p style={{ color: 'red' }} >ERC-20 (ETH) address</p>
                          ) : ""}
                        </div>
                        <input
                          type="text"
                          className="form-control placeholder-no-fix payment-field"
                          autoComplete="off"
                          onChange={this.onReceiverChange.bind(this)}
                          value={this.state.receiver}
                          name="receiver"
                          placeholder={this.props.activeLanguage.code === "bg" ? "Получаване в портфейл" : "Receiving in wallet address"}
                        />
                      </div>
                    </div>
                    <div
                      className="label-animated notice-new buy-notice"
                      style={{
                        marginBottom: "50px", marginLeft: "49px",
                        width: "82%"
                      }}
                    >
                      <label>
                        <Translate id="chechAddressIsCorrect" />
                      </label>
                    </div>
                    {this.state.formErrors.receiver != "" ? (
                      <div
                        className="help-block with-errors"
                      // style={{ marginLeft: "16px" }}
                      >
                        <span>
                          <Translate id="errors.receiverIsInvalid" />
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div />
                )}
                {this.state.receivingMethod === "binance" ? (
                  <div
                    className={`col-md-12 form-group ${!this.state.receiverValid ? "has-error" : "has-success"
                      }`}
                  >
                    <div className="custom-style-select no-border label-animated has-content payment-field-wrapper">
                      <div className=" sm-margi payments-wrapper-titlen">
                        {/* <strong>
                          <Translate id="receiving" /> <Translate id="in" /> <Translate id="binance" />
                        </strong> */}
                      </div>
                      <input
                        type="text"
                        className="form-control placeholder-no-fix payment-field"
                        autoComplete="off"
                        onChange={this.onReceiverChange.bind(this)}
                        value={this.state.receiver}
                        name="receiver"
                        placeholder={this.props.activeLanguage.code === "bg" ? "Получаване в Binance" : "Receiving in Binance"}
                      />
                    </div>
                    <div
                      className="label-animated notice-new large-buy-notice"
                      style={{ marginBottom: "50px", marginLeft: "49px", width: "82%", textAlign: "left" }}
                    >
                      <label>
                        <Translate id="checkBinanceIsCorrect" />
                      </label>
                    </div>
                    {this.state.formErrors.receiver != "" ? (
                      <div
                        className="help-block with-errors"
                      // style={{ marginLeft: "16px" }}
                      >
                        <span>
                          <Translate id="errors.receiverIsInvalid" />
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div />
                )}
                <div className="col-md-12 promocode-wrapper-new" style={{ marginTop: "30px" }}>
                  <div className={`${this.state.promoCodeUse ? "d-none" : ""}`} style={{ width: "100%" }}>
                    <div className="form-group">
                      <div className="input-icon payment-field-wrapper">
                        <div className="label-animated has-content">
                          <input
                            type="text"
                            className="form-control placeholder-no-fix touched payment-field"
                            autoComplete="off"
                            value={this.state.promoCode}
                            onChange={this.onPromoCodeChange.bind(this)}
                            name="promocode"
                            placeholder={this.props.activeLanguage.code === "bg" ? "Промокод" : "Promocode"}
                            style={{ fontSize: "15px" }}
                          />
                          {/* <label className="control-label visible-ie8 visible-ie9" style={{ marginLeft: "49px" }}>
                            <Translate id="promoCode" />
                          </label> */}
                        </div>
                      </div>
                    </div>
                    {this.state.formErrors.promoCode != "" ? (
                      <div
                        className="help-block with-errors"
                        style={{
                          position: "relative",
                          top: "-40px",
                        }}
                      >
                        <span>
                          <Translate id="errors.promoCodeIsInvalid" />
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={` ${this.state.promoCodeUse ? "d-none" : ""}`}>
                    <button
                      type="button"
                      className="apply-promocode-button-new"
                      // style={{
                      //   position: "absolute",
                      //   backgroundColor: "transparent",
                      //   border: "none",
                      //   borderLeft: "1px solid grey",
                      //   padding: "5px 10px 5px 16px",
                      //   right: "14%",
                      //   top: "12px",
                      //   zIndex: "2"
                      // }}
                      onClick={this.onPromoCodeSubmit.bind(this)}
                    >
                      <Translate id="apply" />
                    </button>
                  </div>
                  <div className={`col-md-12 ${!this.state.promoCodeUse ? "d-none" : ""}`} >
                    <div className="form-info-box"
                      style={{
                        width: "82%",
                        margin: "auto",
                        textAlign: "left",
                      }}
                    >
                      <strong><Translate id="promoCodeUsed" /></strong>
                    </div>
                  </div>
                </div>
                {(this.state.paymentMethod == "easypay" || this.state.paymentMethod == "bank") && (
                  <div className="col-md-12 payment-info-section">
                    <p>
                      <strong>
                        <Translate id="taxFees" />
                      </strong>
                    </p>
                    <p>
                      {" "}
                      {formatMoney(this.state.fees)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </p>
                  </div>
                )}
                <div className="col-md-12 text-center">
                  {(this.state.paymentMethod == "easypay" || this.state.paymentMethod == "bank") ? (
                    <div style={{ marginBottom: '35px' }}>
                      <Translate id="easyPayTexts.pt1" />
                      <br />
                      <Translate id="easyPayTexts.pt2" />
                      <br />
                    </div>
                  ) : ""}

                  <button
                    type="submit"
                    className="btn btn-action btn-rounded crypto-button crypto-sell"
                    disabled={!this.state.formValid}
                  >
                    <Translate id="buy" /> <Translate id="for" />{" "}
                    <span>
                      {formatMoney(this.state.total)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </span>
                  </button>
                </div>
              </div>
              <div className="col-md-12 text-center mt-5">
                {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                  <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                ) : ""}
              </div>
              <div className="clearfix"></div>
            </form>
            <br />
          </div >
        </div >
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ user, auth, transaction, config, currency, rates }) {
  return { user, auth, transaction, config, currency, rates };
}

export default connect(
  mapStateToProps,
  { fetchTransaction, sendTransaction, clearTransaction, validatePromoCode, setError }
)(withLocalize(AccountBuyFormNew));
