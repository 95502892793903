import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUnseenMessages, sendCharityTransaction } from '../../actions';

import TransactionsTablePublicNew from '../transactions/TransactionsTablePublicNew';

const headingStyle = {
  margin: 0,
  color: '#000',
  'margin-top': '50px',
  fontWeight: 'bold'
};

function paragraphStyle(extraMargin = false) {
  return {
    marginTop: '20px',
    marginBottom: extraMargin ? '40px' : '0'
  };
}

function imageStyle(maxHeight, extraMarginLeft = 0, extraMarginTop = 0, extraMarginBottom = 0) {
  return {
    maxHeight,
    marginLeft: extraMarginLeft,
    marginTop: extraMarginTop,
    marginBottom: extraMarginBottom
  };
}

const rowSpacing = {
  marginTop: '120px'
};

const yellowButtonStyle = {
  backgroundColor: '#FFC107',
  color: '#000',
  borderRadius: '50px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '16px'
};

function OverlappingButtons(props) {
  const { yellowText, blueText, blueWidth = '325px' } = props;
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button className="charity-btn-yellow" style={yellowButtonStyle}>
        {yellowText}
      </button>
      <button className="charity-btn-blue"
        style={{
          backgroundColor: '#163973',
          color: '#fff',
          borderRadius: '50px',
          padding: '10px 20px',
          width: blueWidth,
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
          fontSize: '16px',
          position: 'absolute',
          top: 0,
          left: 'calc(100% - 15px)'
        }}
      >
        {blueText}
      </button>
    </div>
  );
}

function getBalanceFor(accounts, coinType) {
  if (!accounts || typeof accounts !== 'object') return 0;

  const arr = Object.values(accounts);
  const found = arr.find((acc) => acc.type === coinType);
  if (!found) return 0;

  return parseFloat(found.balance) || 0;
}

class CryptoPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '10',
      selectedTab: 'USDC'
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.copyAddress = this.copyAddress.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleSend() {
    if (!this.props.isLoggedIn) {
      alert("Трябва да сте логнат, за да изпратите транзакция.");
      return;
    }

    const userConfirmed = window.confirm("Сигурни ли сте, че искате да направите дарение?");
    if (!userConfirmed) {
      return;
    }

    const payload = {
      accountType: this.state.selectedTab,
      amount: this.state.amount
    };

    console.log(this.props);
    console.log(payload);

    this.props.sendCharityTransaction(payload)
      .then((data) => {

        //alert(`Дарението е изпратено успешно: ${payload.amount} ${payload.accountType}`);
      })
      .catch((error) => {
        console.error("Грешка при изпращане на транзакцията:", error);
      });
  }

  copyAddress(addr) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(addr)
        .then(() => alert(`Address copied: ${addr}`))
        .catch((err) => alert(`Copy failed: ${err}`));
    } else {
      alert('Clipboard API not supported in this browser.');
    }
  }

  changeTab(tab) {
    const defaultAmounts = {
      USDC: '10',
      BTC: '0.0002',
      ETH: '0.005'
    };
    this.setState({
      selectedTab: tab,
      amount: defaultAmounts[tab]
    });
  }

  render() {
    const { selectedTab, amount } = this.state;
    const { isLoggedIn, balances } = this.props;

    const balance = balances[selectedTab.toLowerCase()] || 0;
    const addresses = {
      USDC: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c',
      BTC: '19Cgm6TGpkvSd7cAW1ZNKZXaZwjfprnrHx',
      ETH: '0x8c72e61bb556109c7007d8ee8781a9f62071fa0c'
    };

    const qrCodeSrc = `/img/${selectedTab.toLowerCase()}.png`;

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}>
        <div style={{
          maxWidth: '500px',
          width: '100%',
          padding: '20px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {['USDC', 'BTC', 'ETH'].map((coin) => (
              <button
                key={coin}
                onClick={() => this.changeTab(coin)}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                  margin: '0 5px',
                  backgroundColor: selectedTab === coin ? '#00b4f4' : '#ccc',
                  color: selectedTab === coin ? '#fff' : '#000'
                }}
              >
                {coin}
              </button>
            ))}
          </div>

          {isLoggedIn && (
            <div style={{ marginBottom: '20px' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                <b>Current Balance:</b> {balance.toFixed(4)} {selectedTab}
              </p>
            </div>
          )}

          {isLoggedIn && (
            <React.Fragment>
              <div>
                <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
                  {selectedTab} Amount to donate (Off-Chain):
                </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="number"
                    value={amount}
                    onChange={this.handleAmountChange}
                    style={{
                      flex: 1,
                      marginRight: '10px',
                      width: '100%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc'
                    }}
                    min="0"
                  />
                  <button
                    onClick={this.handleSend}
                    style={{
                      backgroundColor: '#00b4f4',
                      color: '#fff',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px'
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}

          <div style={{ "margin-top": '15px' }}>
            <label style={{ display: 'block', fontSize: '14px', marginBottom: '5px' }}>
              {selectedTab} Address (On-Chain):
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={addresses[selectedTab]}
                readOnly
                style={{
                  flex: 1,
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '10px'
                }}
              />
              <button
                onClick={() => this.copyAddress(addresses[selectedTab])}
                style={{
                  backgroundColor: '#00b4f4',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '10px',

                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
        <div style={{
          marginLeft: '20px',
          'margin-top': '130px',
          display: 'flex',
          width: '300px',
          flexDirection: 'column',
          alignItems: 'center'
        }} className="charity-qr">
          <h5>{selectedTab} QR Code</h5>
          <img
            src={qrCodeSrc}
            alt={`${selectedTab} QR Code`}
            style={{
              width: '250px',
              border: '1px solid #ccc',
              objectFit: 'cover'
            }}
          />
        </div>
      </div>
    );
  }
}

class SunnyKidsBG extends Component {
  constructor(props) {
    super(props);
    this.scrollToDonateSection = this.scrollToDonateSection.bind(this);
  }

  scrollToDonateSection() {
    const donateSection = document.getElementById("donate");
    if (donateSection) {
      donateSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const autoScroll = queryParams.get("scrollToDonate");

    if (autoScroll === "true") {
      this.scrollToDonateSection();
    }
  }
  render() {
    const isLoggedIn = this.props.auth && this.props.auth.authenticated;
    const { accounts } = this.props;
    const balances = {
      usdc: getBalanceFor(accounts, 'USDC'),
      btc: getBalanceFor(accounts, 'BTC'),
      eth: getBalanceFor(accounts, 'ETH')
    };
    return (
      <div className="intro-text">
        <div className="article-full">
          <div className="transcard">
            <div className="">
              <div className="">
                <div className="row align-items-center text-left my-5 my-section">
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      BONEX за Фондация <br />
                      “Слънчевите Деца 2024”
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle()}
                    >
                      <b>Помaгате на дете</b> със синдром на Даун.<br />
                      <b>Получавате намаление</b> от следващата ни комисионна.
                    </p>
                    <div className="text-left">
                      <br />
                      <button
                        className="btn-link"
                        onClick={this.scrollToDonateSection}
                        style={{ color: '#000', backgroundColor: '#ffc543', fontWeight: 500 }}
                      >
                        Дари крипто
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-7 text-center text-md-right">
                    <img
                      src="/img/width_1194.webp"
                      alt="First section"
                      className="img-fluid charity-img-fluid"
                      style={imageStyle('300px')}
                    />
                  </div>
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      Дарете Крипто <br />  и Получете Отстъпка
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle(true)}
                    >
                      Вашата подкрепа може да промени живота на дете в нужда.
                    </p>

                    <div>
                      В Бонекс вярваме в добрите дела и искаме да ги насърчим. <b>Затова за всяко дарение от 10 USDC или повече ви даваме 100% отстъпка от нашата комисионна за следващата ви поръчка.</b> <br />Подкрепете каузата – ние ще подкрепим вас.<br /><br />
                    </div>

                    <div className="text-left" style={{ marginTop: '15px', display: 'none' }}>
                      <OverlappingButtons
                        yellowText="10 до 99 USDC"
                        blueText="-30%&nbsp;от&nbsp;следващата транзакция"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px', display: 'none' }}>
                      <OverlappingButtons
                        yellowText="100 до 999 USDC"
                        blueText="-50%&nbsp;от&nbsp;следващата транзакция"
                        blueWidth="325px"
                      />
                    </div>
                    <div className="text-left" style={{ marginTop: '10px', display: 'none' }}>
                      <OverlappingButtons
                        yellowText="1000 USDC и повече"
                        blueText="-100%&nbsp;от&nbsp;следващата транзакция"
                        blueWidth="325px"
                      />
                    </div>


                    <div style={{ marginTop: '10px' }}>Еквивалентните суми в ETH и BTC също получават отстъпката. <br /><br />Даренията са отворени и след 31 януари 2025 г., като за тях ще продължим да предлагаме специални отстъпки в зависимост от размера на дарението – защото добрите дела заслужават подкрепа във всяко време.</div>
                  </div>
                  <div
                    className="col-12 col-md-7 text-center text-md-right"
                    style={{ marginTop: '80px', }}
                  >
                    <img
                      src="/img/width_1098.webp"
                      alt="First section"
                      className="img-fluid charity-img-w6 "
                      style={imageStyle('150px', 30, 65)}
                    />
                  </div>
                  <div className="col-12 col-md-2" />
                </div>
                <div
                  className="row align-items-center text-left my-5 my-section"
                  style={rowSpacing}
                >
                  <div className="col-12 col-md-5">
                    <h2 className="section-title text-left" style={headingStyle}>
                      Какво ще постигнем <br />с  фондация „Слънчеви деца 2024“?
                    </h2>
                    <p
                      className="section-description text-left"
                      style={paragraphStyle()}
                    >
                      BONEX с гордост партнира с Криско и неговата фондация, за да подкрепим децата със синдром на Даун и да осигурим по-добро бъдеще за тях.<br /><br />
                      Вашето дарение ще допринесе за:
                    </p>
                    <ul style={{ marginBottom: '40px' }}>
                      <li>Финансиране на лечението и терапиите на децата.</li>
                      <li>Осигуряване на стипендии и образователни програми.</li>
                      <li>Организиране на инициативи за социална интеграция.</li>
                    </ul>
                  </div>
                  <div
                    className="col-12 col-md-7 text-center text-md-right charity-w6"
                    style={{ marginTop: '80px', }}
                  >
                    <img
                      src="/img/w6.png"
                      alt="First section"
                      className="img-fluid charity-img-w6 charity-img-w6a "
                      style={imageStyle('200px', 30, 165, 25)}
                    />
                    <br />
                    <div
                    className="text-center  " style={{ width: '300px', float:'right' }} > 
                    Нашата цел е да създадем най-големия център на Балканите за деца със синдром на Даун и други здравословни проблеми.
                    </div>
                  </div>
                  <div className="col-12 col-md-2" />
                </div>
                <div className="row align-items-center text-left my-5 my-section" style={{ marginTop: '120px' }}>
                  <div className="col-12 col-md-5">
                    <div id="donate"></div>
                    <h2 className="section-title text-left" style={headingStyle}>
                      Дарете Kрипто
                    </h2>

                    <p
                      className="section-description text-left"
                    >
                      <CryptoPayment
                        isLoggedIn={isLoggedIn}
                        balances={balances}
                        account={this.props.account}
                        currency={this.props.currency}
                        sendCharityTransaction={this.props.sendCharityTransaction}
                      />
                    </p>
                  </div>

                </div>
                <div className="row align-items-center text-left my-5 my-section" style={{ marginTop: '120px' }}>
                  <div className="col-12 col-md-10" style={{ marginTop: '10px', 'font-size': "14px" }}>
                    Разяснение относно On-Chain и Off-Chain дарения <br /><br />

                    Предлагаме два метода за дарение – On-Chain и Off-Chain, за да осигурим удобство и ефективност за нашите потребители. Ето как работят те:<br /><br />

                    <b>1. Дарение On-Chain</b><br />
                    При On-Chain даренията криптовалутата се изпраща директно към публичния адрес на фондацията. Тези транзакции се записват в блокчейна и могат да бъдат проверени от всеки потребител, като се заплащат мрежови такси.
                    <br /><br />
                    <b>2. Дарение Off-Chain</b><br />
                    Off-Chain даренията използват вътрешния баланс на платформата. Това е по-бърз и удобен метод, който избягва мрежовите такси. Транзакциите се обработват вътрешно в BoneX и са напълно проследими в платформата. Клиентите с вътрешен баланс също получават отстъпка от 30% до 100% от комисионната за следваща транзакция.
                    <br /><br />
                    И двата метода са надеждни и прозрачни в рамките на съответните системи – блокчейна за On-Chain даренията и платформата BoneX за Off-Chain даренията. Изберете този, който най-добре отговаря на Вашите нужди.
                  </div>
                </div>
                <h2 className="section-title text-left" style={headingStyle}>
                  История на даренията
                </h2>

                <div style={{ marginTop: '40px' }}>
                  <TransactionsTablePublicNew />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    accounts: state.accounts,
    account: state.account,
    currency: state.currency,
    unseenCount: state.messages.unseenCount,
  };
}

export default connect(
  mapStateToProps,
  { sendCharityTransaction, fetchUnseenMessages }
)(SunnyKidsBG);