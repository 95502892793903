import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const HomepageSlider = ({ slides, interval }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, interval);

    return () => clearInterval(slideInterval); // Cleanup on unmount
  }, [slides.length, interval]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="home-slider">
      <div className="home-slider-content">
        {slides.map((slide, index) => (
          <a
            key={index}
            href={slide.href}
            className={`home-slider-item ${
              index === currentSlide ? "active" : ""
            }`}
            style={{ display: index === currentSlide ? "block" : "none" }}
          >
            <img src={slide.src} alt={slide.alt} />
          </a>
        ))}
      </div>

      {/* <button className="home-slider-control-prev" onClick={() => goToSlide((currentSlide - 1 + slides.length) % slides.length)}>
        &#10094;
      </button>
      <button className="home-slider-control-next" onClick={() => goToSlide((currentSlide + 1) % slides.length)}>
        &#10095;
      </button> */}

      <div className="home-slider-indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`indicator ${index === currentSlide ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

HomepageSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  interval: PropTypes.number,
};

HomepageSlider.defaultProps = {
  interval: 3000,
};

export default HomepageSlider;
