import _ from "lodash";
import {
  AUTH_USER,
  SEND_CHARITY_TRANSACTION,
  SEND_CHARITY_TRANSACTION_SUCCESS,
  SEND_CHARITY_TRANSACTION_FAILURE,
  FETCH_BALANCES_SUCCESS,
  FETCH_BALANCES_FAILURE,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_USER_DATA,
  UPDATE_USER_DATA,
  FETCH_ACCOUNTS,
  GENERATE_ACCOUNTS,
  FETCH_ACCOUNT,
  UPDATE_ACCOUNT,
  FETCH_TOTP,
  VERIFY_TOTP,
  UPDATE_TOTP,
  CLEAR_TOTP,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION,
  SEND_TRANSACTION,
  CLEAR_TRANSACTION,
  FETCH_ORDERS,
  FETCH_ASSET,
  CLEAR_ASSET,
  FETCH_RATES,
  CLEAR_USER_DATA,
  CLEAR_USER_ACCOUNTS,
  CLEAR_USER_ACCOUNT,
  CLEAR_USER_TRANSACTIONS,
  CLEAR_USER_ORDERS,
  UPDATE_GENERAL_SETTINGS,
  UPDATE_SECURITY_SETTINGS,
  UPDATE_PASSWORD_SETTINGS,
  UPDATE_PRIVACY_SETTINGS,
  UPDATE_PERSONAL_VERIFICATION,
  UPDATE_ADDRESS_VERIFICATION,
  CLEAR_AUTH_ERROR,
  FETCH_NEWS,
  FETCH_NEWS_ENTRY,
  CLEAR_NEWS,
  FETCH_PAGES_ENTRY,
  FETCH_NETWORK_ADDRESSES,
  FETCH_CONFIG,
  SET_ERROR,
  UNSET_ERROR,
  SET_CURRENCY,
  FETCH_CURRENCIES,
  CLEAR_CURRENCIES,
  FETCH_AFFILIATE,
  CLEAR_AFFILIATE,
  FETCH_BONUSES,
  CLEAR_BONUSES,
  FETCH_MESSAGES,
  FETCH_MESSAGES_ENTRY,
  CLEAR_MESSAGES,
  FETCH_AIRPORTS,
  CLEAR_AIRPORTS,
  FETCH_CARS,
  CLEAR_CARS,
  FETCH_CAR,
  CLEAR_CAR,
  SET_UNSEEN_COUNT,
} from "./types";

/**
 * User & Auth
 */
export const fetchUser = () => async (dispatch, getState, api) => {
  await api
    .get("/auth/user", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_USER_DATA,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const signIn = (userData) => async (dispatch, getState, api) => {
  return await authUser(api, dispatch, "auth/local/signin", userData);
};

export const signUp = (userData) => async (dispatch, getState, api) => {
  return await authUser(api, dispatch, "auth/local/signup", userData);
};

export const authIn = (totp) => async (dispatch, getState, api) => {
  const token = localStorage.getItem("token-tmp");
  await api
    .post("/auth/totp", totp, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      if (response.data.success) {
        localStorage.setItem("token", token);
        localStorage.removeItem("token-tmp");
        dispatch({ type: AUTH_USER });
        dispatch({ type: CLEAR_AUTH_ERROR });
      } else {
        dispatch(authError("Invaild 2FA Token"));
      }
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const generateTotp = (totpMethod) => async (dispatch, getState, api) => {
  const token = localStorage.getItem("token-tmp");
  return await api
    .post(
      "/auth/totp/generate",
      { totpMethod },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((e) => {
      reqError(e, dispatch);
      return e;
    });
};

export const sendTotp = (totpMethod) => async (dispatch, getState, api) => {
  const token = localStorage.getItem("token");
  return await api
    .post(
      "/auth/totp/generate",
      { totpMethod },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((e) => {
      reqError(e, dispatch);
      return e;
    });
};

export const verifyTotp = (totp) => async (dispatch, getState, api) => {
  const token = localStorage.getItem("token");
  return await api
    .post("/auth/totp", totp, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      if (response.data.success) {
        return response.data.success;
      } else {
        return false;
      }
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const requestResetTotp = () => async (dispatch, getState, api) => {
  return await api
    .post("auth/totp/reset-request", {}, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token-tmp") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const resetTotp = (data) => async (dispatch, getState, api) => {
  let formData = new FormData();
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return await api
    .post("auth/totp/reset", formData, {
      headers: {
        ContentType: "multipart/form-data"
      },
    })
    .then((response) => {
      if (response.data !== true) {
        dispatch(authError("Invalid Totp Reset Token"));
      }
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchTotp = () => async (dispatch, getState, api) => {
  await api
    .get("/auth/totp", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_TOTP,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};


export const signOut = () => async (dispatch, getState, api) => {
  if (typeof localStorage != 'undefined') {
    localStorage.removeItem("token");
  }
  dispatch({ type: UNAUTH_USER });
  dispatch({ type: CLEAR_USER_DATA });
  dispatch({ type: CLEAR_USER_ACCOUNTS });
  dispatch({ type: CLEAR_USER_TRANSACTIONS });
  dispatch({ type: CLEAR_USER_ORDERS });
  dispatch({ type: CLEAR_AFFILIATE });
  dispatch({ type: CLEAR_TRANSACTION });
};

export const clearAuthErrors = () => async (dispatch, getState, api) => {
  dispatch({ type: CLEAR_AUTH_ERROR });
};

export const validateEmail = (token) => async (dispatch, getState, api) => {
  return await api
    .post("/users/validate", { token })
    .then((response) => {
      if (!_.isEmpty(response.data)) {
        dispatch({
          type: FETCH_USER_DATA,
          payload: response.data,
        });
        dispatch({ type: CLEAR_AUTH_ERROR });
      } else {
        dispatch(authError("Invalid Validation Token"));
      }
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const forgotPass = ({ email, captcha }) => async (dispatch, getState, api) => {
  dispatch({ type: CLEAR_AUTH_ERROR });
  return await api
    .post("/auth/forgot-pass", { email, captcha })
    .then((response) => {
      if (response.data !== true) {
        dispatch(authError("Invalid Email"));
      }
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const resetPass = (data) => async (dispatch, getState, api) => {
  dispatch({ type: CLEAR_AUTH_ERROR });
  return await api
    .post("/auth/reset-pass", data)
    .then((response) => {
      if (response.data !== true) {
        dispatch(authError("Invalid User Reset Token"));
      }
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchUserReferrals = () => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .get("/users/referrals", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

/**
 * Assets
 */
export const fetchAsset = (assetType) => async (dispatch, getState, api) => {
  await api
    .get("/accounts/asset/" + assetType)
    .then((response) => {
      dispatch({
        type: FETCH_ASSET,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

/**
 * Accounts
 *
 */
export const fetchAccounts = () => async (dispatch, getState, api) => {
  await api
    .get("/accounts/my", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_ACCOUNTS,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchAccount = (accountType) => async (
  dispatch,
  getState,
  api
) => {
  await api
    .get("/accounts/my/" + accountType, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_ACCOUNT,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const generateAccounts = () => async (dispatch, getState, api) => {
  await api
    .get("/accounts/generate", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updateAccount = (accountData) => async (
  dispatch,
  getState,
  api
) => {
  await api
    .post("/accounts/update", accountData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updatePersonalVerification = (data) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({ type: CLEAR_AUTH_ERROR });
  let formData = new FormData();
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return await api
    .post("/users/verification/personal", formData, {
      headers: {
        ContentType: "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e.response.data;
    });
};

export const updateAddressVerification = (data) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({ type: CLEAR_AUTH_ERROR });
  let formData = new FormData();
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return await api
    .post("/users/verification/address", formData, {
      ContentType: "multipart/form-data",
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      if (response.data !== true) {
        dispatch(authError("Invalid"));
      }
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updateGeneralSettings = (settings) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/settings/general", settings, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: UPDATE_GENERAL_SETTINGS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updateSecuritySettings = (settings) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/settings/security", settings, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: UPDATE_SECURITY_SETTINGS,
          payload: response.data,
        });
      }
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updatePasswordSettings = (settings) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/auth/change-pass", settings, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: UPDATE_PASSWORD_SETTINGS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const updatePrivacySettings = (settings) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/settings/privacy", settings, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: UPDATE_PRIVACY_SETTINGS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraTerms = (data) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/cards/terms", data, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraUser = (data) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/cards/create-user", data, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraPhone = (data) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/cards/validate-phone", data, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraOrder = (data) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/users/cards/order-card", data, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraGetUser = () => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .get("/users/cards/get-user", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraGetCard = () => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .get("/users/cards/get-card", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const trastraPromo = (data) => async (
  dispatch,
  getState,
  api
) => {
  data.language = localStorage.getItem("languageCode") || 'bg';
  return await api
    .post("/users/cards/promo", data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchUserCard = () => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .get("/users/cards/user-card", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const validatePromoCode = (promoCodeData) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/accounts/promocode/validate", promoCodeData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const activateBonus = (bonusCodeData) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/accounts/bonuses/activate", bonusCodeData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const activateGameBonus = (bonusCodeData) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/accounts/bonuses/activate/game", { bonus: bonusCodeData }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const sendTransaction = (transactionData) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .post("/accounts/transactions/new", transactionData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: SEND_TRANSACTION,
        payload: response.data,
      });
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const sendGuestTransaction = (transactionData) => async (
  dispatch,
  getState,
  api
) => {
  await api
    .post("/accounts/transactions/guest", transactionData)
    .then((response) => {
      dispatch({
        type: SEND_TRANSACTION,
        payload: response.data,
      });
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const clearTransaction = () => async (dispatch, getState, api) => {
  return dispatch({ type: CLEAR_TRANSACTION });
};

/**
 * Transactions
 */
export const fetchTransactions = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/transactions/my", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return dispatch({
        type: FETCH_TRANSACTIONS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchPublicTransactions = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/transactions/charity/sunnykids")
    .then((response) => {
      return dispatch({
        type: FETCH_TRANSACTIONS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};
 
/**
 * Orders
 */
export const fetchOrders = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/orders/my", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return dispatch({
        type: FETCH_ORDERS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const cancelOrder = (orderId) => async (dispatch, getState, api) => {
  return await api
    .post("/accounts/orders/cancel",
      { orderId },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Affiliate
 */
export const fetchAffilaite = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/affiliate", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return dispatch({
        type: FETCH_AFFILIATE,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Bonus
 */
export const fetchBonuses = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/bonuses/my", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return dispatch({
        type: FETCH_BONUSES,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Rates
 */
export const fetchRates = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/rates")
    .then((response) => {
      return dispatch({
        type: FETCH_RATES,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchTrastraRate = (accountType) => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/trastra/rate/" + accountType)
    .then((response) => {
      return dispatch({
        type: FETCH_RATES,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchTrastraRates = () => async (dispatch, getState, api) => {
  return await api
    .get("/accounts/trastra/rates")
    .then((response) => {
      return dispatch({
        type: FETCH_RATES,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Networks
 */

export const fetchNetworkAddress = (accountType, network) => async (dispatch, getState, api) => {
  return await api
    .get("accounts/my/" + accountType + "/" + network + "/address", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return dispatch({
        type: FETCH_NETWORK_ADDRESSES,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Config
 */
export const fetchConfig = () => async (dispatch, getState, api) => {
  return await api
    .get("/settings/config")
    .then((response) => {
      if (response.data.length) {
        let resultArr = [];
        response.data.forEach((data) => (resultArr[data.the_key] = data.value));
        return dispatch({
          type: FETCH_CONFIG,
          payload: resultArr,
        });
      } else {
        return dispatch({
          type: FETCH_CONFIG,
          payload: response.data,
        });
      }
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchCountry = () => async (dispatch, getState, api) => {
  return await api
    .get("/settings/country")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

/**
 *  FAQ
 */
export const getFAQ = () => async (dispatch, getState, api) => {
  return await api
    .get("pages/faq")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 *  News
 */
export const fetchNews = (lang) => async (dispatch, getState, api) => {
  dispatch({
    type: CLEAR_NEWS
  });
  return await api
    .get("pages/news/lang/" + lang)
    .then((response) => {
      return dispatch({
        type: FETCH_NEWS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchNewsEntry = (newsSlug) => async (dispatch, getState, api) => {
  return await api
    .get("pages/news/" + newsSlug)
    .then((response) => {
      return dispatch({
        type: FETCH_NEWS_ENTRY,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const clearNews = () => async (dispatch, getState, api) => {
  return dispatch({
    type: CLEAR_NEWS
  });
};

/**
 * Properties
 */
export const addProperty = (data, images) => async (dispatch, getState, api) => {
  return await api
    .post("pages/property/add", { form: data, images: images }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
}

export const addPropertyImage = (data) => async (dispatch, getState, api) => {
  let formData = new FormData();
  formData.append('images', data);

  return await api
    .post("pages/property/add/image", formData, {
      headers: {
        ContentType: "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
}

/**
 *  Pages
 */
export const fetchLocalizedPagesEntry = (pageLang, pageSlug) => async (
  dispatch,
  getState,
  api
) => {
  return await api
    .get("pages/page/" + pageLang + "/" + pageSlug)
    .then((response) => {
      return dispatch({
        type: FETCH_PAGES_ENTRY,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Errors
 */
export const setError = (error) => async (dispatch, getState, api) => {
  return dispatch({
    type: SET_ERROR,
    payload: error,
  });
};

export const unsetError = () => async (dispatch, getState, api) => {
  return dispatch({
    type: UNSET_ERROR,
    payload: null,
  });
};

/**
 * Currencies
 */

// export const currencies = {
//   BGN: {
//     type: "BGN",
//     symbol: "bgn",
//     rate: 1,
//   },
//   EUR: {
//     type: "EUR",
//     symbol: "eur",
//     rate: 1.9558,
//   },
//   // RON: {
//   //   type: "RON",
//   //   symbol: "ron",
//   //   rate: 0.39558,
//   // },
//   // USD: {
//   //   type: "USD",
//   //   symbol: "usd",
//   //   rate: 1.65558,
//   // },
//   USDT: {
//     type: "USDT",
//     symbol: "usdt",
//     rate: 1.80827000,
//   },
// };

export const fetchCurrencies = () => async (dispatch, getState, api) => {
  return await api
    .get("accounts/rates/fiat")
    .then((response) => {
      dispatch({
        type: FETCH_CURRENCIES,
        payload: response.data
      });
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
}

export const setCurrency = (currency) => async (dispatch, getState, api) => {
  localStorage.setItem("currency", currency.type);
  return dispatch({
    type: SET_CURRENCY,
    payload: currency,
  });
};

export const initCurrency = (currency) => async (dispatch, getState, api) => {
  return dispatch({
    type: SET_CURRENCY,
    payload: currency,
  });
};

/**
 * Verification
 */
export const getVerificationToken = (level) => async (dispatch, getState, api) => {
  return await api
    .get("users/verification/token/" + level, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Flights
 */
export const fetchAirports = () => async (dispatch, getState, api) => {
  return await api
    .get("flights/airports")
    .then((response) => {
      return dispatch({
        type: FETCH_AIRPORTS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const clearAirports = () => async (dispatch, getState, api) => {
  return dispatch({ type: CLEAR_AIRPORTS });
};

export const searchAirports = (query) => async (dispatch, getState, api) => {
  return await api
    .get("flights/airports")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const fetchAircrafts = () => async (dispatch, getState, api) => {
  return await api
    .get("flights/aircrafts")
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const doFlightSearch = (data) => async (dispatch, getState, api) => {
  return await api
    .post("flights/search", data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const doFlightRequest = (data) => async (dispatch, getState, api) => {
  return await api
    .post("flights/request", data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

/**
 * Cars
 */
export const fetchCars = (type, provider) => async (dispatch, getState, api) => {
  return await api
    .get(`cars/specified/${type}/${provider}`)
    .then((response) => {
      return dispatch({
        type: FETCH_CARS,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const clearCars = () => async (dispatch, getState, api) => {
  return dispatch({ type: CLEAR_CARS });
};

export const fetchCar = (id) => async (dispatch, getState, api) => {
  return await api
    .get("cars/" + id)
    .then((response) => {
      return dispatch({
        type: FETCH_CAR,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const clearCar = () => async (dispatch, getState, api) => {
  return dispatch({ type: CLEAR_CARS });
};

export const doCarRequest = (data) => async (dispatch, getState, api) => {
  return await api
    .post("cars/request", data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error(e);
      return reqError(e, dispatch);
    });
};

/**
 * Iris
 */
export const fetchUserBank = () => async (dispatch, getState, api) => {
  return await api
    .get("/users/bank", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchIbans = () => async (dispatch, getState, api) => {
  return await api
    .get("/users/bank/ibans", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchIbanBalance = (ibanId) => async (dispatch, getState, api) => {
  return await api
    .post("/users/bank/balance", { ibanId: ibanId }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchIbanTransactions = (ibanId) => async (dispatch, getState, api) => {
  return await api
    .post("/users/bank/transactions", { ibanId: ibanId }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

/**
 * Messages
 */
export const fetchMessages = () => async (dispatch, getState, api) => {
  return await api
    .get("/users/messages", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_MESSAGES,
        payload: response.data
      });
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchMessagesEntry = (messageId) => async (dispatch, getState, api) => {
  return await api
    .get("users/messages/" + messageId)
    .then((response) => {
      return dispatch({
        type: FETCH_MESSAGE_ENTRY,
        payload: response.data,
      });
    })
    .catch((e) => {
      return reqError(e, dispatch);
    });
};

export const clearMessages = () => async (dispatch, getState, api) => {
  return dispatch({
    type: CLEAR_MESSAGES
  });
};

export const setMessageSeen = (messageId) => async (dispatch, getState, api) => {
  return await api
    .post("/users/messages/action", {
      action: 'Seen',
      id: messageId
    }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

export const fetchUnseenMessages = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/users/messages/unseen", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    if (response.data.success) {
      const unseenCount = response.data.result.length;
      dispatch(setUnseenCount(unseenCount));
      return response.data;
    } else {
      console.error("Failed to fetch unseen messages:", response.data.error);
      dispatch(setUnseenCount(0));
      return response.data;
    }
  } catch (e) {
    reqError(e, dispatch);
    dispatch(setUnseenCount(0));
    return e;
  }
};

/**
 * Games
 */
export const setGamesAction = (gameId) => async (dispatch, getState, api) => {
  return await api
    .post("/users/games/action", {
      action: 'Off',
      id: gameId
    }, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      reqError(e, dispatch);
    });
};

/**
 * Functions
 */
async function authUser(api, dispatch, endpoint, data) {
  data.language = localStorage.getItem("languageCode") || 'bg';
  const response = api.post(endpoint, data);
  return response
    .then((response) => {
      if (response.data.token) {
        const token = response.data.token;
        dispatch({ type: CLEAR_AUTH_ERROR });
        return new Promise((resolve, reject) => {
          api
            .get("/auth/user", {
              headers: { Authorization: "Bearer " + token },
            })
            .then((resp) => resolve(resp))
            .catch((e) => reject(e));
        })
          .then((resp) => {
            dispatch({
              type: FETCH_USER_DATA,
              payload: resp.data,
            });

            if (resp.data.totp === "none") {
              localStorage.setItem("token", token);
              dispatch({ type: AUTH_USER });
            } else {
              localStorage.setItem("token-tmp", token);
            }
            return resp.data;
          })
          .catch((e) => {
            reqError(e, dispatch);
          });
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("token-tmp");
        dispatch({ type: UNAUTH_USER });
        dispatch(authError(response.data.message));
        return false;
      }
    })
    .catch((error, response) => {
      localStorage.removeItem("token");
      localStorage.removeItem("token-tmp");
      dispatch({ type: UNAUTH_USER });
      dispatch(authError(error.response.data.message));
      return false;
    });
}

function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

function reqError(e, dispatch) {
  if (e.response) {
    if (e.response.status == 401) {
      return dispatch({
        type: UNAUTH_USER,
      });
    } else {
      dispatch({
        type: SET_ERROR,
        payload: e.response.data.message,
      });
    }
  } else if (e.request) {
    dispatch({
      type: SET_ERROR,
      payload: e.request,
    });
  } else {
    dispatch({
      type: SET_ERROR,
      payload: e.message,
    });
  }

  return;
}

export const sendCharityTransaction = (payload) => async (dispatch, getState, api) => {
  dispatch({ type: SEND_CHARITY_TRANSACTION });

  try {
    const response = await api.post("/accounts/charity/send", payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response.data.success) { 
      dispatch({
        type: SEND_CHARITY_TRANSACTION_SUCCESS,
        payload: response.data,
      });

      alert(`Дарението е изпратено успешно: ${payload.amount} ${payload.accountType} discountCode: ${response.data.discountCode}`);
      window.location.reload();

      dispatch(fetchUser());
      dispatch(fetchAccounts());
      dispatch(fetchUnseenMessages());
      
    } else {
      alert(`${response.data.error}`);
      dispatch({
        type: SEND_CHARITY_TRANSACTION_FAILURE,
        payload: response.data.message || "Грешка при изпращане на транзакцията.",
      });
      dispatch(authError(response.data.message || "Грешка при изпращане на транзакцията."));
    }
  } catch (error) {
    dispatch({
      type: SEND_CHARITY_TRANSACTION_FAILURE,
      payload: error.message || "Грешка при изпращане на транзакцията.",
    });
    dispatch(reqError(error, dispatch));
  }
};

export const setUnseenCount = (count) => ({
  type: SET_UNSEEN_COUNT,
  payload: count,
});