import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { fetchUser, fetchTotp, updateSecuritySettings, setError } from '../../actions';

class SecuritySettingsFormNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totp: props.user.totp ? props.user.totp : 'none',
            token: '',
            submitted: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onTotpChanged = this.onTotpChanged.bind(this);
        this.onTokenChanged = this.onTokenChanged.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.fetchTotp();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.user.totp) {
            this.setState({ totp: props.user.totp });
        }
    }

    onTotpChanged(event) {
        this.setState({ totp: event.target.value });
    }

    onTokenChanged(event) {
        this.setState({ token: event.target.value });
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.updateSecuritySettings(this.state).then((data) => {
            this.setState({
                submitted: data.success
            });

            if (data.error) {
                this.props.setError('Invalid 2FA code');
            }
        });
    }

    render() {
        if (this.state.submitted) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="successLabel" />!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.settings" /></h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="">
                    <form onSubmit={this.onSubmit}>
                        <div className="row" style={{ margin: '0px -28px' }}>
                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <p><Translate id="unlockCode" /></p>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <h5>{this.props.totp.unlock}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12 form-group">
                                    <label className="visible-ie8 visible-ie9" style={{fontWeight: "500"}}>
                                        <input
                                            name="totp"
                                            type="radio"
                                            className="placeholder-no-fix"
                                            autoComplete="off"
                                            value="google"
                                            checked={this.state.totp === 'google'}
                                            onChange={this.onTotpChanged}
                                            required={true}
                                        /> &nbsp;Google Authenticator
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <p><Translate id="2FASettings.googlePartOne" /></p>
                                    <div className="text-center">
                                        <img src={this.props.totp.img} style={{ margin: "20px 0px 30px 0px" }} alt="TOTP QR" />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group">
                                    <p><Translate id="2FASettings.googlePartTwo" /></p>
                                    <br />
                                    <div className="label-animated has-content">
                                        <label className="input-label visible-ie8 visible-ie9 two-fa-label"><Translate id="2FASettings.totpCode" /></label>
                                        <input
                                            type="text"
                                            name="google_token"
                                            className="form-control two-fa-form-field"
                                            onChange={this.onTokenChanged}
                                            style={{ color: 'white' }}
                                        />
                                    </div>
                                    <p><Translate id="2FASettings.googlePartThree" /></p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12 form-group">
                                    <label className="visible-ie8 visible-ie9" style={{fontWeight: "500"}}>
                                        <input
                                            name="totp"
                                            type="radio"
                                            className="placeholder-no-fix"
                                            autoComplete="off"
                                            value="email"
                                            checked={this.state.totp === 'email'}
                                            onChange={this.onTotpChanged}
                                            required={true}
                                        /> &nbsp;Email Authenticator
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="col align-self-end">
                                    <button className="profile-button" type="submit" style={{ marginTop: "15px" }}><Translate id="save" /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
    }
};

function mapStateToProps({ user, totp }) {
    return { user, totp };
}

export default connect(mapStateToProps, { fetchUser, fetchTotp, updateSecuritySettings, setError })(SecuritySettingsFormNew);