import _ from "lodash";
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import ReactDOM from "react-dom";
import { Translate } from "react-localize-redux";

class CommissionPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="commission-modal-wrapper" onClick={this.props.onClosePopupClick}>
        <div className="commission-modal">
          <div className="commission-modal-card">
            <div className="modal-card-text">
              <button type="button" onClick={this.props.onClosePopupClick}>
                x
              </button>

              <div>
              <iframe
        width="332"
        height="591" 
        src="https://www.youtube.com/embed/iQ09XISOy1o?autoplay=1&mute=1"
        title="BONEX за Фондация “Слънчевите Деца 2024”"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
                  <div class="" style={{ "padding-bottom": "25px" }}><a class="btn-link-box orange-btn" href="/charity-sunnykids">Научи повече</a></div>
              </div>


              {/* <strong className="fire-icon-modal" style={{ fontSize: "65px" }}>
                🔥 0%{" "}
              </strong>
              <strong className="heading-text-modal">
                {this.props.activeLanguage.code === "en"
                  ? "Commission on Crypto Purchases and Sales!"
                  : "Комисионна за покупка и продажба на криптовалути!"}
              </strong>
              {this.props.activeLanguage.code === "en" ? (
                <div
                  className="deadline-modal"
                  style={{ marginTop: "55px", fontSize: "28px" }}
                >
                  Until December 20th
                </div>
              ) : (
                <div
                  className="deadline-modal"
                  style={{ marginTop: "25px", fontSize: "28px" }}
                >
                  До 20-ти Декември
                </div>
              )}
              {this.props.activeLanguage.code === "en" ? (
                <div
                  className="final-text-modal"
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    marginTop: "41px",
                  }}
                >
                  Don’t miss out!
                </div>
              ) : (
                <div
                  className="final-text-modal"
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    marginTop: "21px",
                  }}
                >
                  Не пропускайте!
                </div>
              )} */}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(CommissionPopup);
