import _ from 'lodash';
import ExcelJS from 'exceljs'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchTransactions } from '../../actions';

const TABLE_TYPES = {
    ALL: "ALL",
    DEPOSIT: "DEPOSIT",
    WITHDRAW: "WITHDRAW",
}

class TransactionsTableNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableType: "ALL",
            filteredTransactions: [],
            currentPage: 1,
            rowsPerPage: 10,
        };
        this.exportToExcel = this.exportToExcel.bind(this)
        this.filterData = this.filterData.bind(this)
    }

    async handlePageChange(newPage) {
        this.setState({ currentPage: newPage });
    };

    async filterData(allTransactions, transactionType) {
        if (transactionType !== "ALL") {
            this.setState({
                ...this.state,
                filteredTransactions: allTransactions.filter((transaction) => transaction.action === transactionType),
                currentPage: 1,
                tableType: transactionType
            })
        } else {
            this.setState({
                ...this.state,
                filteredTransactions: allTransactions,
                currentPage: 1,
                tableType: transactionType
            })
        }
        // return filteredOrders = allOrders.filter((order) => order.action === ordersType)
        // filteredOrders = ["qko"]
    }

    async exportToExcel() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Table Data');

        const tableData = Object.values(this.props.transactions);

        tableData.forEach(row => {
            const dataObject = {
                account: row.account_type,
                action: row.action,
                amount: row.amount,
                status: row.status,
                date: row.time,
                txReference: row.reference,
            }
            const rowValues = Object.values(dataObject);
            worksheet.addRow(rowValues);
        });

        const insertedRowInherited = worksheet.insertRow(1, [], 'i');

        worksheet.columns = [
            { header: this.props.activeLanguage.code === `en` ? `Account` : `Акаунт`, key: 1, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: this.props.activeLanguage.code === `en` ? `Action` : `Действие`, key: 2, width: 16, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: this.props.activeLanguage.code === `en` ? `Amount` : `Количество`, key: 2, width: 16, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: this.props.activeLanguage.code === `en` ? `Status` : `Статус`, key: 3, width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: this.props.activeLanguage.code === `en` ? `Date` : `Дата`, key: 4, width: 23, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
            { header: this.props.activeLanguage.code === `en` ? `Tx Reference` : `TX Reference`, key: 5, width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
        ];

        worksheet.getRow(1).font = { size: 13, bold: true };


        // Generate Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'table_data.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        });
    };


    UNSAFE_componentWillMount() {
        this.props.fetchTransactions();
    }

    componentDidUpdate() {
        // const dataEvent = document.createEvent('HTMLEvents');
        // dataEvent.initEvent('datatable-data', true, true);
        // document.dispatchEvent(dataEvent);
    }

    transactionLink(transaction) {
        let result = "";

        switch (transaction.account_type) {
            case 'ETH':
            case 'USDT':
            case 'USDC':
            case 'UNI':
            case 'LINK':
            case 'AAVE':
            case 'COMP':
            case 'MATIC':
            case 'MKR':
            case 'MASK':
            case 'SHIB':
            case 'APE':
            case 'SNX':
            case 'CRV':
            case 'SAND':
            case 'MANA':
            case 'DYDX':
            case 'BLUR':
            case 'GALA':
            case 'STORJ':
            case 'CHZ':
            case 'SUSHI':
            case 'GRT':
            case 'LRC':
            case 'AGLD':
            case 'LOOKS':
                result = 'https://etherscan.io/tx/' + transaction.reference;
                break;
            case "BTC":
                result = 'https://explorer.bitcoin.com/btc/tx/' + transaction.reference;
                break;
            case "BCH":
                result = 'https://explorer.bitcoin.com/bch/tx/' + transaction.reference;
                break;
            case "RVN":
                result = 'https://ravencoin.network/tx/' + transaction.reference;
                break;

            case "XRP":
                result = "https://xrpscan.com/ledger/" + transaction.reference;
                break;


            default:
                break;
        }

        return result;


        // if (transaction.account_type == 'BTC') {
        //     return 'https://explorer.bitcoin.com/btc/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'ETH' || transaction.account_type == 'USDT' || transaction.account_type == 'UNI' || transaction.account_type == 'LINK' || transaction.account_type == 'AAVE'
        //     || transaction.account_type == 'USDC' || transaction.account_type == 'WLD' || transaction.account_type == 'COMP' || transaction.account_type == 'AMP' || transaction.account_type == 'MATIC'
        //     || transaction.account_type == 'ARB' || transaction.account_type == 'MKR' || transaction.account_type == 'MASK' || transaction.account_type == 'SHIB' || transaction.account_type == 'PEPE'
        //     || transaction.account_type == 'APE' || transaction.account_type == 'SNX' || transaction.account_type == 'LDO' || transaction.account_type == 'CRV' || transaction.account_type == 'RNDR'
        //     || transaction.account_type == 'SAND' || transaction.account_type == 'MANA' || transaction.account_type == 'CAKE' || transaction.account_type == 'FLOKI' || transaction.account_type == 'MEME'
        // ) {
        //     return 'https://etherscan.io/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'BCH') {
        //     return 'https://explorer.bitcoin.com/bch/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'RVN') {
        //     return 'https://ravencoin.network/tx/' + transaction.reference;
        // } else {
        //     return '';
        // }
    }

    rows(currentData) {
        return _.map(currentData, (transaction, number) => {
            const isDonation = transaction.reference.includes("DONATION");
            const txReferenceContent = isDonation
                ? transaction.reference
                : `${transaction.reference.substring(0, 23)}...`;
            return (
                <tr key={transaction.id} className='custom-table-row'>
                    <td>
                        <div className="fee-box">
                            {transaction.account_type}
                        </div>
                    </td>
                    <td align="center">
                        {transaction.action}
                    </td>
                    <td>
                        <strong className="amount">{parseFloat(transaction.amount).toFixed(transaction.decimals)} {transaction.account_type}</strong>
                    </td>
                    <td>
                        <strong className="status">{transaction.status}</strong>
                    </td>
                    <td>
                        <time>
                            {new Intl.DateTimeFormat(this.props.translate("lang"), {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            }).format(new Date(transaction.time))}
                        </time>
                    </td>
                    <td>
                    {isDonation ? ( 
                        <span>{txReferenceContent}</span>
                    ) : ( 
                        <a
                            href={this.transactionLink(transaction)}
                            title=""
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {txReferenceContent}
                        </a>
                    )}
                </td>
                </tr>
            );
        });
    }

    render() {
        const { currentPage, rowsPerPage } = this.state;
        const data = Object.values(this.props.transactions);

        const startIdx = (currentPage - 1) * rowsPerPage;
        const displayedData = this.state.filteredTransactions.length >= 0 && this.state.tableType !== "ALL" ? this.state.filteredTransactions : data
        const currentData = displayedData.slice(startIdx, startIdx + rowsPerPage);

        const totalPages = Math.ceil(this.state.filteredTransactions.length > 0 ? this.state.filteredTransactions.length / rowsPerPage : data.length / rowsPerPage);


        return (
            <div className="">
                <div className="scroll-box" >
                    {/* <button className="profile-button" onClick={this.exportToExcel}>Export to Excel</button> */}
                    <button className={`filter-by-type-order-button ${this.state.tableType === "ALL" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "ALL")}>All</button> {" / "}
                    <button className={`filter-by-type-order-button ${this.state.tableType === "WITHDRAW" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "WITHDRAW")}>Withdraw</button>{" / "}
                    <button className={`filter-by-type-order-button ${this.state.tableType === "DEPOSIT" ? "active-table" : ""}`} style={{ marginLeft: "5px" }} onClick={() => this.filterData(data, "DEPOSIT")}>Deposit</button>
                    <button className="profile-button export-button" onClick={this.exportToExcel}>Export to Excel</button>
                    <table className="custom-table custom-table-new" style={{ overflow: 'scroll' }}>
                        <thead className='custom-table-head'>
                            <tr className='custom-table-row'>
                                <th>
                                    <strong><Translate id="account" /></strong>
                                </th>
                                <th align="center">
                                    <strong><Translate id="action" /></strong>
                                </th>
                                <th>
                                    <strong><Translate id="amount" /></strong>
                                </th>
                                <th>
                                    <strong><Translate id="status" /></strong>
                                </th>
                                <th>
                                    <strong><Translate id="date" /></strong>
                                </th>
                                <th>
                                    <strong>Tx Refference</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.rows(currentData)}
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className="pagination-button-new" type="button" onClick={() => this.handlePageChange(1)}>
                        <i className="fal fa-chevron-double-left"></i>
                    </button>
                    <button className="pagination-button-new" onClick={() => currentPage > 1 ? this.handlePageChange(currentPage - 1) : ""}>
                        <i className="fal fa-chevron-left"></i>
                    </button>
                    {Array.from({ length: totalPages }, (_, idx) => (
                        <button
                            key={idx}
                            onClick={() => this.handlePageChange(idx + 1)}
                            disabled={currentPage === idx + 1}
                            className={`pagination-button-new ${currentPage === idx + 1 ? `active-button` : ``}`}
                        >
                            {idx + 1}
                        </button>
                    )).slice(currentPage < 4 ? 0 : currentPage - 3, currentPage < 4 ? 5 : currentPage + 2)
                        // .slice(startingPage, currentPage < 5 ? 5 : startingPage + 5)

                    }
                    <button className="pagination-button-new" onClick={() => currentPage === totalPages ? "" : this.handlePageChange(currentPage + 1)}>
                        <i className="fal fa-chevron-right"></i>
                    </button>
                    {
                        <button className="pagination-button-new" onClick={() => this.handlePageChange(totalPages)}>
                            <i className="fal fa-chevron-double-right"></i>
                        </button>
                    }
                </div>
            </div>
        );
    }
};


function mapStateToProps({ transactions }) {
    return { transactions };
}

export default connect(mapStateToProps, { fetchTransactions })(withLocalize(TransactionsTableNew));